<template>
  <div v-html="html" class="container main-width license-container"></div>
</template>

<script>
  import { getStrategy } from '@/service'

  export default {
    name: 'DevelopmentStrategy',
    data() {
      return {
        html: '',
      }
    },
    mounted() {
      getStrategy().then(({ data }) => {
        this.html = data
      })
    },
  }
</script>
